import { Helmet } from "react-helmet"

import React from 'react'

const SEOna = () => (
  <Helmet>
    <meta name="google-site-verification" content="2nwTFQGBC8L0Qok6LNZ7d8TuLYnn9FZC2a3GPgtUPOc" />
  </Helmet>
)

export default SEOna
